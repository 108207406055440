<template>
	<div>
		<v-textarea
			filled
			rounded
			:disabled="isDisabled"
			:value="value"
			@change="(value) => $emit('update', value)"
		></v-textarea>
	</div>
</template>

<style lang="less">
@import "../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
export default {
	name: "Comment",
	props: {
		value: {},
	},
	computed: {
		isDisabled(){
			return this.disabled || this.question.disable_input
		}
	},
	inject: ["question", "disabled"]
};
//
</script>
"
